
























































































































.box-change {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    background-color: #FF5C00;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .warning-changepass {
      font-weight: 700;
      color: darkorange;
      font-size: clamp(15px, 2.5vw, 16.2px);
      margin-bottom: 15px;
      text-transform: capitalize;
      text-align: center;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .input-group-text {
        background: transparent;
        padding: 0;
        border: 1px solid #e9ecef;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        border: 1px solid #e9ecef;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        &:focus {
          box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
  }
}
